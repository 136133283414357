import axios from "axios";

// Definición de la URL base para las peticiones
const BASE_URL = "https://api.pd.nttdataco.com/ms-catalog";

const apiService = {
  getProductsByFilter: async (data) => {
    try {
      const response = await axios.post(`${BASE_URL}/${"product"}`, data, {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      });

      if (response.status == 200) {
        return response.data;
      } else {
        return response.data.error.message;
      }
    } catch (error) {
      // Maneja el error aquí
      console.error("Error al hacer la petición POST:", error);
      throw error; // Puedes relanzar el error para manejarlo en el componente que llame a esta función
    }
  },
};

export default apiService;
