import { ListCategory } from "./Components/ListCategory";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";

export default function Root({ apiInstance }) {
  const queryClient = new QueryClient();

  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <ListCategory />
      </QueryClientProvider>
    </div>
  );
}
