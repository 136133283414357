import React, { useState, useEffect } from "react";
import "../Styles/ListCategory.css";
import { ProductDetail } from "./ProductDetail";
import { Drawer, message } from "antd";
import apiService from "../services/products";

export const ListCategory = () => {
  const [open, setOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [productData, setProductData] = useState(null);
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState("0");
  const [productSearch, setProductSearch] = useState("0");

  useEffect(() => {
    const handleRoutingEvent = (e) => {
      const url = new URL(location.href);
      const categoryId = url.search.split("category=")[1];
      const productId = url.search.split("product=")[1];
      setCategory(categoryId);
      setProductSearch(productId);
      let sendData = {};
      if (categoryId !== "Todas") {
        sendData = {
          category: categoryId,
          // minimumAvailability: 1,
          // maximumAvailability: 10,
          // higherPrice: 2000000,
          // lowerPrice: 100000,
        };
      }
      getProducts(sendData);
    };

    window.addEventListener("single-spa:routing-event", handleRoutingEvent);

    // Elimina el listener del evento al desmontar el componente
    return () => {
      window.removeEventListener(
        "single-spa:routing-event",
        handleRoutingEvent
      );
    };
  }, []);

  const getProducts = (sendData) => {
    apiService
      .getProductsByFilter(sendData)
      .then(async (response) => {
        setProducts(response.serviceResponse);
        let totalp: any = response.serviceResponse.length;
        window.dispatchEvent(
          new CustomEvent("total_products", { detail: { total: totalp } })
        );
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  };

  const success = () => {
    messageApi.open({
      type: "success",
      content: "El producto fue agregado exitosamente",
    });
  };

  const addToCart = async (product, quantity) => {
    let cart = JSON.parse(localStorage.getItem("cart")) || [];
    const productIndex = cart.findIndex((item) => item.id === product?.id);
    if (productIndex === -1) {
      cart.push({ ...product, quantity });
    } else {
      cart[productIndex].quantity += quantity;
    }
    await localStorage.setItem("cart", JSON.stringify(cart));
    await success();
    await window.dispatchEvent(
      new CustomEvent("update_to_cart", { detail: { message: "add to cart" } })
    );
  };

  const handleProduct = (data) => {
    setProductData(data);
    setOpen(!open);
  };

  const formatName = (name) => {
    return name.replace("-", " ");
  };

  return (
    <div className="list-category">
      {contextHolder}
      <Drawer
        title={productData?.title}
        placement="right"
        onClose={() => setOpen(!open)}
        open={open}
      >
        <ProductDetail product={productData} />
      </Drawer>
      <div className="product-grid product-grid--flexbox">
        <div className="product-grid__wrapper">
          {products.length > 0 ? (
            products.map((product) => (
              <div key={product.id} className="product-grid__product-wrapper">
                <div className="product-grid__product">
                  <div className="product-grid__img-wrapper">
                    <img
                      src={product.image}
                      alt="Img"
                      className="product-grid__img"
                    />
                  </div>
                  <div className="details">
                    <span className="product-grid__category">
                      {formatName(product.category)}
                    </span>
                    <span className="product-grid__brand">{product.brand}</span>
                    <span className="product-grid__title">{product.title}</span>
                    <span className="product-grid__price">
                      ${product.price}
                    </span>
                    <div className="product-grid__extend-wrapper">
                      <div className="product-grid__extend">
                        <p className="product-grid__description">
                          {product.description}
                        </p>
                        <span
                          role="presentation"
                          onClick={() => addToCart(product, 1)}
                          className="product-grid__btn product-grid__add-to-cart"
                        >
                          <i className="fa fa-cart-arrow-down"></i> Agregar
                        </span>
                        <span
                          role="presentation"
                          onClick={() => handleProduct(product)}
                          className="product-grid__btn product-grid__view"
                        >
                          <i className="fa fa-eye"></i> Ver más
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="product-grid__empty">
              <img
                src="https://img.freepik.com/vector-gratis/ilustracion-concepto-seo_114360-5846.jpg?w=740&t=st=1682977053~exp=1682977653~hmac=455f95560545fa4efd2a7e697b3ceabf06759fb51dc9f6c062f45e5f35ebda52"
                alt="."
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
