import React, { useState } from "react";
import "../Styles/ProductDetail.css";
import { formatter } from "../common/utils/formatt";
import { message } from "antd";

export const ProductDetail = (props: { product: any }) => {
  const [quantity, setquantity] = useState(1);
  const { product } = props;
  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: "success",
      content: "El producto fue agregado exitosamente",
    });
  };

  let cart = {};
  if (localStorage.getItem("cart")) {
    cart = JSON.parse(localStorage.getItem("cart"));
  }
  const addToCart = () => {
    let cart = JSON.parse(localStorage.getItem("cart")) || [];
    const productIndex = cart.findIndex((item) => item.id === product?.id);
    if (productIndex === -1) {
      // El producto no existe en el carrito, lo agregamos
      cart.push({ ...product, quantity });
    } else {
      // El producto ya existe en el carrito, actualizamos la cantidad
      cart[productIndex].quantity += quantity;
    }
    localStorage.setItem("cart", JSON.stringify(cart));
    success();
    window.dispatchEvent(
      new CustomEvent("update_to_cart", { detail: { message: "add to cart" } })
    );
  };
  return (
    <div className="product-detail">
      {contextHolder}
      <img src={product.image} alt="." />
      <span className="product-detail__price">
        ${formatter.format(product.price)}
      </span>
      <span className="product-detail__description">{product.description}</span>
      <div className="product-detail__actions">
        <span
          role="presentation"
          onClick={addToCart}
          className="product-grid__btn product-grid__add-to-cart"
        >
          <i className="fa fa-cart-arrow-down"></i> Agregar
        </span>
        <div className="product-detail__quantity">
          <span role="presentation" onClick={() => setquantity(quantity - 1)}>
            {" "}
            -{" "}
          </span>
          <input value={quantity} />
          <span role="presentation" onClick={() => setquantity(quantity + 1)}>
            {" "}
            +{" "}
          </span>
        </div>
      </div>
    </div>
  );
};
